import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Wrapper = styled.section`
  position: relative;
  // margin-top: 90px;
  min-height: 300px;

  @media only screen and (min-width: 640px) {
    margin-top: 109px;
  }
`

const Row = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
`

const Col = styled.div`
  position: relative;
  width: 50%;
  background-color: ${props => props.theme.colors.tan};

  @media screen and (max-width: 640px) {
    width: 100%;
    min-height: 293px;
  }
`

const BgImg = styled(Img)`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  // z-index: -1;
  min-height: 552px;
  height: auto;
  & > img {
    object-fit: ${props => props.fit || 'cover'};
    object-position: ${props => props.position || '50% 50%'};
  }

  &::before {
    z-index: 1;
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
  
  @media screen and (max-width: 640px) {
    min-height: 507px !important;
  }
`

const TitleContent = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.tan};

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 2em;
  padding-right: 2em;

`

const Title = styled.h1`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  // padding-top: 90px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  line-height: 33px;
  font-size: 28px;
  text-align: center;
  letter-spacing: 0.177083px;
  color: ${props => props.theme.colors.black};
  @media screen and (max-width: 600px) {
    line-height: 25px;
    font-size: 20px;
    letter-spacing: 0.43px;
    font-weight: 500;
  }
`

const Subtitle = styled.h1`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  padding-top: 0.3em;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  font-size: 38px;
  text-align: center;
  letter-spacing: 0.78px;
  color: ${props => props.theme.colors.black};
  margin-top: 5px;
  @media screen and (max-width: 600px) {
    line-height: 23px;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0.557143px;
  }
`

const Footer = styled.div`
  position: absolute;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-right: 77px;
  padding-bottom: 11px;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 11px;
  letter-spacing: 0.430833px;
  text-align: right;
  color: ${props => (props.isBlue ? '#000000' : '#FFFFFF')};
  a {
    color: ${props => (props.isBlue ? '#000000' : '#FFFFFF')};
  }

  @media screen and (max-width: 600px) {
    font-family: Raleway;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    font-size: 10px;
    text-align: right;
    letter-spacing: 0.409091px;
    padding: 5px 10px;
  }
`

const Hero = props => {

  return (
    <Wrapper>
      <Row>
        <Col>
          <BgImg height={props.height} fluid={props.image.fluid} />
          <Footer
            style={{
              color: props.isBlue ? '#000000' : '#FFFFFF',
              a: props.isBlue ? '#000000' : '#FFFFFF',
            }}
            isBlue={props.isBlue}
            dangerouslySetInnerHTML={{
              __html:
              props.footer.childMarkdownRemark != null
              ? props.footer.childMarkdownRemark.html
              : '',
            }}
            />
        </Col>
        <Col>
            <TitleContent>
              <Title>{props.title}</Title>
              <Subtitle>{props.subtitle}</Subtitle>
            </TitleContent>
        </Col>
      </Row>
    </Wrapper>
  )
}

export default Hero
