import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Wrapper = styled.section`
  margin-top: ${props => (props.marginTop ? props.marginTop : '4em')};
  display: flex;
  flex-direction: column;
  align-items: center;
  .content-decription {
    max-width: ${props => props.theme.breakpoint.medium}px;
    padding: 0 2.5em;
  }

  @media screen and (max-width: ${props => props.theme.breakpoint.medium}px) {
    .content-decription {
      max-width: ${props => props.theme.breakpoint.smallMedium}px;
      padding: 0 6em;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.smallMedium}px) {
    .content-decription {
      max-width: ${props => props.theme.breakpoint.smallMedium}px;
      padding: 0 2em;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.small}px) {
    .content-decription {
      max-width: ${props => props.theme.breakpoint.smallMedium}px;
      padding: 0 1.3em;
    }
  }
`

const Row = styled.div`
  width: 100%;
  max-width: ${props => props.theme.breakpoint.medium}px;

  justify-content: center;
  width: 100%;

  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;

  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;

  @media screen and (max-width: ${props => props.theme.breakpoint.medium}px) {
    max-width: ${props => props.theme.breakpoint.smallMedium}px;
  }

`

const Col = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  padding: 1.5em 2.5em;
  align-items: center;
  justify-content: center;

  .separator {
    width: 100%;
    border-width: 1.17672px;
    height: 1.17672px;
    border-bottom-color: ${props => props.theme.colors.black};
  }

  @media screen and (max-width: ${props => props.theme.breakpoint.medium}px) {
    flex-basis: 100%;
    padding: 0 6em;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.smallMedium}px) {
    flex-basis: 100%;
    padding: 0 2em;
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.small}px) {
    flex-basis: 100%;
    padding: 0 1.3em;
  }
`

const Title = styled.h1`
  font-family: Raleway;
  font-size: 60px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 70px;
  letter-spacing: 0.239186px;
  text-align: left;
  color: ${props => props.theme.colors.black};

  @media screen and (max-width: ${props => props.theme.breakpoint.medium}px) {
    font-size: 38px;
    line-height: 53px;
    margin: 0.8em 0;
  }
` 

const Separator = styled.div`
  width: 100%;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
  height: 3px;
  border-bottom-color: ${props => props.theme.colors.black};

  margin-top: 4.6em;

  @media screen and (max-width: ${props => props.theme.breakpoint.medium}px) {
    margin-top: 0;
    width: 75%;
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const CampaignImage = styled(Img)`
  min-height: 100%;
  width: 100%;
`

const ImageFooter = styled.h1`
  position: absolute;
  margin: auto;
  margin-bottom: 1em;
  object-fit: contain;
  font-family: Raleway;
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: right;
  color: ${props => props.theme.colors.white};
  bottom: 0;
  right: 12px;
`

const Description = styled.div`
  margin: auto;
  margin-bottom: 5em;

  object-fit: contain;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: 0.239186px;
  text-align: left;

  color: ${props => props.theme.colors.black};

  & a {
    color: ${props => props.theme.colors.black};
  }
  
`

const Subtitle = styled.h3`
  width: 100%;
  margin-bottom: 0.5em;

  object-fit: contain;
  font-family: Raleway;
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 125%;
  letter-spacing: 0.06px;
  text-align: left;
  color: ${props => props.theme.colors.tertiary};

  @media screen and (max-width: ${props => props.theme.breakpoint.medium}px) {
    margin-top: 2em;
    margin-bottom: 1.2em;
    text-align: center;
  }
`

const CampaignGoalSection = props => {
  const descriptionHtmltext = props.description.childMarkdownRemark.html || '';
  var descriptionWithLink = descriptionHtmltext.replace('<a', '<a target="_blank"');
  return (
    <Wrapper marginTop={props.marginTop}>
      <Row>
        <Col className='col-left'>
          <Title>
            {props.title || ''}
          </Title>
          <Separator/>
        </Col>
        <Col className='col-right'>
          <ImageContainer>
            <CampaignImage fluid={props.image.fluid}/>
            <ImageFooter>{props.image.description}</ImageFooter>  
          </ImageContainer>
        </Col>
      </Row>
      <div className="content-decription">
        <Subtitle>
          {props.subtitle}
        </Subtitle>
        <Description 
          dangerouslySetInnerHTML={{ __html: descriptionWithLink }}
        />
      </div>
    </Wrapper>
  )
}

export default CampaignGoalSection
