import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'

import HomeCaseStudies from '../components/HomeCaseStudies'
import CaseInsightsSection from '../components/CaseInsightsSection'
import CampaignGoalSection from '../components/CampaignGoalSection'
import GetQuoteSection from '../components/GetQuoteSection'
import TestimonialCard from '../components/testimonialCard'
import SimpleGallery from '../components/SimpleGallery'

import HeroCase from '../components/HeroCase'
import SEO from '../components/SEO'

const TestimonialContent = styled.div`
  margin: 0 auto;
  max-width: 777px;
  @media screen and (max-width: ${props => props.theme.breakpoint.medium}px) {
    padding: 0 3em;
  }
`

const CaseStudyTemplate = ({ data }) => {
  const content = data.contentfulCaseStudy
  const caseStudies =
    data.allContentfulCaseStudy.edges.length > 3
      ? data.allContentfulCaseStudy.edges.slice(0, 3)
      : data.allContentfulCaseStudy.edges
  const contentHome = data.contentfulHomePage

  return (
    <Layout whiteLgNav>
      <Helmet>
        <title>{`${config.siteTitle}`}</title>
      </Helmet>
      <SEO pagePath={`case/${content.slug}`} postNode={content} pageSEO />

      <HeroCase
        title={content.heroTitle}
        subtitle={content.title}
        image={content.heroImage}
        isBlue={false}
        footer={content.heroImageFooter}
      />
      
      <CaseInsightsSection 
        engagements={content.engagements}
        impressions={content.impressions}
        reach={content.reach}
        contentCreated={content.contentCreated}
        influencers={content.influencers}
        websiteClicks={content.websiteClicks}
      />

      <CampaignGoalSection 
        title={content.campaignGoalTitle}
        image={content.campaignGoalImage}
        subtitle={content.campaignGoalSubtitle}
        description={content.campaignGoalDescription}
      />

      <GetQuoteSection 
        noMargin
        consultationTitle={contentHome.completeQuestionnaireTitle}
        consultationSubtitle={contentHome.completeQuestionnaireSubtitle}
        linkTo="/signup/bform"
      />

      <SimpleGallery 
        images={content.middleGallery}
      />

      <TestimonialContent>  
        <TestimonialCard 
          name={content.testimonialName}
          heroImage={content.testimonialHeroImage}
          brandLogo={content.testimonialBrandImage}
          body={content.testimonialBody}
          brandLogoHeight={'22px'} />
      </TestimonialContent>

      <SimpleGallery 
        images={content.lastGallery}
      />

      <HomeCaseStudies
        title={content.otherCasesTitle}
        caseStudies={caseStudies}
        marginBottom={'4em'}
      />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulCaseStudy(slug: { eq: $slug }) {
      title
      heroTitle
      slug
      id
      heroImage {
        title
        description
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroPreview {
        title
        fluid(maxWidth: 347) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroImageFooter {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      brandLogo {
        title
        fluid(maxWidth: 300) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      testimonialBrandImage {
        title
        fluid(maxWidth: 300) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      metaDescription {
        internal {
          content
        }
      }
      engagements
      impressions
      reach
      contentCreated
      influencers
      campaignGoalTitle
      websiteClicks
      campaignGoalImage {
        title
        description
        fluid(maxWidth: 720) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      campaignGoalSubtitle
      campaignGoalDescription {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 80)
        }
      }
      middleGallery {
        id
        title
        description
        fluid(maxWidth: 720) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      testimonialName
      testimonialBody {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 80)
        }
      }
      testimonialHeroImage {
        title
        fluid(maxWidth: 51) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      lastGallery {
        id
        title
        description
        fluid(maxWidth: 720) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      otherCasesTitle
    }
    allContentfulCaseStudy(
      sort: { order: ASC, fields: [createdAt] }
      limit: 3
      filter: { slug: { ne: $slug } } ) {
      edges {
        node {
          title
          slug
          id
          heroPreview {
            title
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          brandLogo {
            title
            fluid(maxWidth: 480) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          engagements
          impressions
        }
      }
    }
    contentfulHomePage(version: { eq: "current" }) {
      completeQuestionnaireTitle
      completeQuestionnaireSubtitle {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default CaseStudyTemplate
