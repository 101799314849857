import React from 'react'
import styled from 'styled-components'
import { toStringFormat } from '../utils/numbers'

const Wrapper = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5em;
  align-items: center;
  padding: 0 0 0 0;
  justify-content: center;

  & .tooltip-info-content {
    position: relative;
  }

  & .tooltip-insights {
    display: none;
    min-width: 187px;
    
    text-align: left;
    color: #000;
    box-shadow: 1.43625px 1.43625px 7.18126px rgba(0, 0, 0, 0.1); 
    background: #fff;
    position: absolute;
    z-index: 10;
    padding: 20px 11px 11px;
    border-radius: 5px;
    -webkit-transition: 100ms ease;
    -moz-transition: 100ms ease;
    -o-transition: 100ms ease;
    -ms-transition: 100ms ease;
    transition: 100ms ease;

    font-family: Raleway;
    font-size: 16.1px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 140%;
    letter-spacing: 0.04592px;
    right: -11px;
    top: 32px;
  }

  & .tooltip-info-content:hover .tooltip-insights {
    display: block;
  }

  & .tooltip-insights:after /* triangle decoration */ {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    content: '';
    position: absolute;
    left: calc(100% - 20px);
    top: -10px;
    margin-left: -10px;
    filter: drop-shadow(rgba(0, 0, 0, 0.1) 0.5px -1px 1px);
  }
  
  .row {
    // flex-direction: row; 
    justify-content: center;
    width: 100%;
    max-width: ${props => props.theme.breakpoint.medium}px;

    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;

    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    @media screen and (max-width: ${props => props.theme.breakpoint.smallMedium}px) {
      flex-direction: column; 
    }
  }

  .case-property {
    display: flex;
    flex-direction: row; 
    flex-grow: 0;
    flex-shrink: 0;
    flex: 1;
    // flex-basis: 20%;
    padding:  1em 1.4em;
    justify-content: center;

    @media screen and (max-width: ${props => props.theme.breakpoint.smallMedium}px) {
      // flex-basis: 50%;
    }
  }

  /* .case-property.property-left {
    justify-content: flex-end;
  }
  .case-property.property-right {
    justify-content: flex-start;
  } */

  .case-property .property {
    display: flex;
    flex-direction: column;
  }

  & h3.case-property-value {
    font-family: Raleway;
    font-size: 42px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 45px;
    letter-spacing: 0.3px;
    color: ${props => props.theme.colors.black};
    text-align: center;
    margin-top: 0.5em;
  }

  & h3.case-property-title {
    font-family: Raleway;
    font-size: 19px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-top: 0.4em;
    color: ${props => props.theme.colors.tertiary};
    margin-right: 3px;
  }

  & .case-title-content {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    & img {
      height: 17.52px;
      width: 17.52px;
      margin-bottom: 2px;
      margin-left: 3px;
    }
  }
  
  @media screen and (max-width: 790px) {
    flex-direction: column;
  }

`

const CaseInsightsSection = props => {
  return (
    <Wrapper>
      <div className="row">
        {props.websiteClicks &&
          <div className="case-property property-left">
            <div className="property">
              <h3 className="case-property-value">{toStringFormat(props.websiteClicks)}</h3>
              <h3 className="case-property-title">WEBSITE CLICKS</h3>
            </div>
          </div>
        }
        <div className="case-property property-left">
          <div className="property">
            <h3 className="case-property-value">{toStringFormat(props.influencers)}</h3>
            <h3 className="case-property-title">INFLUENCERS</h3>
          </div>
        </div>
        <div className="case-property">
          <div className="property">
            <h3 className="case-property-value">{toStringFormat(props.contentCreated)}</h3>
            <h3 className="case-property-title">CONTENT CREATED</h3>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="case-property">
          <div className="property">
            <h3 className="case-property-value">{toStringFormat(props.engagements)}</h3>
            <div className="case-title-content">
              <h3 className="case-property-title"> ENGAGEMENTS  </h3>
              <div
                className="tooltip-info-content"
              >
                <img src="/icons/info-circle.svg" alt="Information"/>
                <div className="tooltip-insights top">
                  <div className="tooltip-content">
                    Total likes, comments, shares and saves
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="case-property">
          <div className="property">
            <h3 className="case-property-value">{toStringFormat(props.reach)}</h3>
            <div className="case-title-content">
              <h3 className="case-property-title">REACH</h3>
              <div
                className="tooltip-info-content"
              >
                <img src="/icons/info-circle.svg" alt="Information"/>
                <div className="tooltip-insights top">
                  <div className="tooltip-content">
                    Total number of unique accounts who saw post
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="case-property property-right">
          <div className="property">
            <h3 className="case-property-value">{toStringFormat(props.impressions)}</h3>
            <div className="case-title-content">
              <h3 className="case-property-title"> IMPRESSIONS  </h3>
              <div
                className="tooltip-info-content"
              >
                <img src="/icons/info-circle.svg" alt="Information"/>
                <div className="tooltip-insights top">
                  <div className="tooltip-content">
                    Total number of times users saw posts
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default CaseInsightsSection
