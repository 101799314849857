import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  margin: ${props => (props.marginTop ? props.marginTop : '5.6em')} 0 5.2em 0;
  @media screen and (max-width: ${props => props.theme.breakpoint.smallMedium}px) {
    margin: ${props => (props.marginTop ? props.marginTop : '4em')} 0 3.8em 0;
  }
`

const Row = styled.div`
  justify-content: space-between;
  width: 100%;
  max-width: ${props => props.theme.breakpoint.medium}px;

  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;

  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;

  @media screen and (max-width: ${props => props.theme.breakpoint.extraLarge}px) {
    padding: 3em;
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.medium}px) {
    padding: 2em;
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.smallMedium}px) {
    width: 100%;
    padding: 2em 2em 0.5em;
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.small}px) {
    padding: 1em 1em 0.3em;
  }
`

const ImageContent = styled(Img)`
  display: flex;
  flex-direction: row; 
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;

  width: 100%;
  padding-bottom: auto;
`

const ImageFooter = styled.h5`
  position: absolute;
  margin: auto;
  margin-bottom: 1em;
  object-fit: contain;
  font-family: Raleway;
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: right;
  color: ${props => props.theme.colors.white};
  bottom: 0;
  right: 12px;
`

const ImageCard = styled.div`
  position: relative;
  width: 47%;
  padding: 0;

  &.with-margin-bottom {
    margin: 0 0 4em;
  }

  @media screen and (max-width: ${props => props.theme.breakpoint.smallMedium}px) {
    width: 100%;
    margin: 0 0 2em;
    &.with-margin-bottom {
      margin: 0 0 2em;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.small}px) {
    margin: 0 0 1em;
    &.with-margin-bottom {
      margin: 0 0 1em;
    }
  }

`

const SimpleGallery = props => {
  let imageCardClass = ''
  if (props.images.length > 2) imageCardClass = 'with-margin-bottom'
  return (
    <Wrapper marginTop={props.marginTop}>
      <Row>
        {props.images && props.images.map(itemImage => (
          <ImageCard key={itemImage.id} className={imageCardClass}>
            <ImageContent fluid={itemImage.fluid}/>
            {/* <ImageFooter>{itemImage.description}</ImageFooter> */}
          </ImageCard>
        ))}
      </Row>
    </Wrapper>
  )
}

export default SimpleGallery
